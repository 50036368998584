// extracted by mini-css-extract-plugin
export var container = "newstemplate-module--container--3VC6u";
export var nav = "newstemplate-module--nav--1hZoU";
export var listContainer = "newstemplate-module--listContainer--3Vuq5";
export var welcomeHeader = "newstemplate-module--welcomeHeader--2TyeM";
export var testLink = "newstemplate-module--testLink--3qEwL";
export var modal = "newstemplate-module--modal--1f92j";
export var modaloff = "newstemplate-module--modaloff--2eX_D";
export var modalcontent = "newstemplate-module--modalcontent--33tkp";
export var animatetop = "newstemplate-module--animatetop--3HP2h";
export var modalheader = "newstemplate-module--modalheader--DtLtG";
export var modalbody = "newstemplate-module--modalbody--LSyFV";
export var popup = "newstemplate-module--popup--C18-1";
export var popdown = "newstemplate-module--popdown--2C0ZI";
export var previewwindow = "newstemplate-module--previewwindow--2MBQ4";
export var headerlink = "newstemplate-module--headerlink--3ePga";
export var header = "newstemplate-module--header--45m4q";
export var newscover = "newstemplate-module--newscover--21mfm";
export var title = "newstemplate-module--title--2moXD";
export var cover = "newstemplate-module--cover--1qAZ_";
export var newsContainer = "newstemplate-module--newsContainer--18zdU";
export var newsContent = "newstemplate-module--newsContent--1yA-j";
export var list = "newstemplate-module--list--1GYmZ";
export var linkHeader = "newstemplate-module--linkHeader--1wCmX";
export var newsimagePreview = "newstemplate-module--newsimagePreview--2ru1t";
export var postitle = "newstemplate-module--postitle--4apo8";
export var descriptionarray = "newstemplate-module--descriptionarray--17FC8";
export var link = "newstemplate-module--link--9BihC";
export var cardHeader = "newstemplate-module--cardHeader--1Dzs8";